import * as React from "react";
import { Link } from "gatsby";

import { Box, Heading, Text, useColorMode } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const What = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color={colorMode === "light" ? "gray.500" : "whiteAlpha.900"}
            _hover={
              colorMode === "light"
                ? { color: "#333" }
                : { color: "whiteAlpha.700" }
            }
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>
        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l, #44CF6E, #27CFC9)"
          bgClip="text"
        >
          Qu'est ce qu'une Product First Company ?
        </Text>

        <Text fontSize="xl" my="40px">
          Penser Product First c'est avant tout répondre aux questions "quelle
          est la traduction de ma stratégie en expérience, quel est son impact
          et comment le mesurer ?".{" "}
          <b>
            Pour y parvenir, il convient de réconcilier le sens, l’impact et la
            performance, en replaçant le Produit au cœur de l'organisation.
          </b>
        </Text>
        <Text
          p="20px"
          bg="#27CFC9"
          my="40px"
          color="white"
          fontSize="xl"
          fontStyle="italic"
        >
          Le Produit, comme catalyseur pour favoriser les implications entre la
          direction et les équipes, entre la stratégie et le déploiement
          opérationnel.
        </Text>
        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            Le sens
          </Heading>
          <Text
            mt="10px"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            color="gray.500"
          >
            La vision à 3-5 ans
          </Text>
          <Text fontSize="xl" my="4">
            Avoir une entreprise axée Produit nécessite une mission clairement
            définie, à laquelle tous les collaborateurs de l’entreprise puissent
            adhérer.{" "}
            <b>
              Cette vision à long terme est la métrique principale qui doit
              traduire à la fois la valeur pour les utilisateurs, mais aussi la
              valeur pour l’entreprise
            </b>
            , l’une et l’autre devant être liées.
          </Text>
        </Box>
        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            L’impact
          </Heading>
          <Text
            mt="10px"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            color="gray.500"
          >
            L'expérience à 12-24 mois
          </Text>
          <Text fontSize="xl" my="4">
            La qualité de l’expérience vécue par les collaborateurs et par les
            clients est essentielle au succès de l’entreprise. En interne,{" "}
            <b>
              l'approche Produit aide à hiérarchiser/prioriser la feuille de
              route et vis-à-vis de l'externe, elle est un levier pour
              identifier finement les besoins
            </b>
            , les attentes et les irritants des utilisateurs.
          </Text>
        </Box>
        <Box my="60px">
          <Heading
            as="h2"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
          >
            La performance
          </Heading>
          <Text
            mt="10px"
            size="xl"
            fontWeight="extrabold"
            lineHeight="1.2"
            letterSpacing="tight"
            color="gray.500"
          >
            Le suivi des résultats tous les 3-6 mois
          </Text>
          <Text fontSize="xl" my="4">
            <b>
              Au centre de la méthodologie, les Objective and Key Results (OKR)
              servent d’engrenage principal, entre la stratégie et son
              exécution.
            </b>{" "}
            Appliquer des OKR au produit, en définissant des objectifs par
            nature cross-fonctionnels, transverses à l’entreprise, permet d’en
            obtenir toute la puissance et les bénéfices : collaboration,
            alignement, transparence.
          </Text>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default What;
